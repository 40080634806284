/* eslint-disable no-param-reassign */
import { isEmpty, last } from 'lodash';
import moment from 'moment';
import uuid from 'react-uuid';
import { MAX_HISTORICAL_YEARS } from 'common/config/app';
import {
  FIRST_ROW_TITLE,
  FISCAL_QUARTER,
  FISCAL_YEAR,
  FORMAT_YEAR_TITLE,
  LTM,
  LTM_QUARTER,
  NTM,
  NTM_QUARTER,
} from 'common/constants/financials';
import { END_OF_YEAR_FORMAT } from 'pages/Financials/data/dateFormatConstants';
import { getYearQuarters, MOMENT_DEFAULT_DATE_FORMAT } from 'utillities';
import { getLtmQuarters, getNtmQuarters, getRecentQuarters } from 'utillities/getYearQuarters';

const loadFinancialStatementData = (
  selectedMeasurementDate,
  financialStatement,
  fiscalYearData,
  setPeriods,
  setCollapsibleColumns,
  historicalYearsProps,
  currentFiscalYearEnd
) => {
  const { setAdditionalHistoricalYears, showAdditionalHistoricalYears } = historicalYearsProps;
  const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY';
  const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';

  if (!selectedMeasurementDate) {
    return;
  }
  const mdDate = moment(selectedMeasurementDate.date, DISPLAY_DATE_FORMAT);
  const monthDayMD = `${mdDate.month() + 1}-${mdDate.date()}`;
  const { end_month, end_day } = fiscalYearData.fiscal_year_end;
  const monthDayFY = `${end_month}-${end_day}`;

  const generateParentId = period => period?.id || period?.column_ref || uuid();

  const isFiscalQuarter = (period, quarter) =>
    period.period_type === FISCAL_QUARTER && moment(period.statement_date).format(DEFAULT_DATE_FORMAT) === quarter.date;

  const measurementDate = selectedMeasurementDate?.date;
  const prevPeriods = [];
  const nextPeriods = [];
  const generatedPeriods = [];

  const auxPeriods = financialStatement.financial_statement_periods || [];
  const fiscalYearPeriods = auxPeriods.filter(p => p.period_type === FISCAL_YEAR);
  const statementYears = fiscalYearPeriods.map(p => parseInt(p.statement_date, 10));
  const currentPeriod = moment(measurementDate);

  // organize statementYears in descendent order
  statementYears.sort((a, b) => b - a);

  const currentFiscalYearEndDate = currentFiscalYearEnd.format(DISPLAY_DATE_FORMAT);
  let tmpCollapsibleColumns = {};
  let tmpAdditionalHistoricalYearsColumns = [];

  const generateParentColumns = ({ id, period, ...info }) => {
    tmpCollapsibleColumns = {
      ...tmpCollapsibleColumns,
      [id]: true,
    };

    let overrideSubtitle = {};

    if ((info.isLTM || info.isNTM) && period?.statement_date) {
      overrideSubtitle = { subtitle: period.statement_date };
    }

    generatedPeriods.push({
      ...info,
      ...period,
      ...overrideSubtitle,
      isParent: true,
      name: info?.title || period.name,
      id,
    });
  };

  const lastAvailableStatementYear = statementYears[statementYears.length - 1];

  const currentPeriodYear = currentPeriod.year();

  // Helper variables to determine the limits of the historical years that we will display
  const defaultLimitYear = currentPeriodYear - MAX_HISTORICAL_YEARS;
  const historicalYears = statementYears.filter(year => year < currentPeriodYear);
  tmpAdditionalHistoricalYearsColumns = historicalYears
    .filter(year => year <= defaultLimitYear)
    .map(year => ({ id: null, year }));

  // If the financial statement has not been saved yet
  // we will display the previous two years
  if (isEmpty(fiscalYearPeriods)) {
    prevPeriods.push(moment(currentFiscalYearEndDate).subtract(2, 'years'));
    prevPeriods.push(moment(currentFiscalYearEndDate).subtract(1, 'years'));
  } else {
    /* Otherwise, create columns for the previous years that are in the financial statement
    but are consecutive */
    const limitAllHistoricalYears = currentPeriodYear - (historicalYears.length + 1);
    const isInAcceptableRange = year => year < currentFiscalYearEnd.year() && year > limitAllHistoricalYears;

    statementYears.forEach(year => {
      const rangeMatch = isInAcceptableRange(year);
      if (rangeMatch) {
        prevPeriods.push(moment(currentFiscalYearEndDate).subtract(currentFiscalYearEnd.year() - year, 'years'));
      }
    });
  }

  const currentPeriodBeforeFiscalYearEnd = currentPeriod.isBefore(currentFiscalYearEnd);
  if (!currentPeriodBeforeFiscalYearEnd) {
    prevPeriods.push(moment(currentFiscalYearEndDate));
  }
  // if none of the available statementYears comply with continuity, add years that do.
  // we know that it will be the previous two years because at this point the currentPeriod will have had to been placed
  if (prevPeriods.length === 0) {
    prevPeriods.push(moment(currentFiscalYearEndDate).subtract(2, 'years'));
    prevPeriods.push(moment(currentFiscalYearEndDate).subtract(1, 'years'));
  }
  // Next Years
  if (currentPeriodBeforeFiscalYearEnd) {
    nextPeriods.push(moment(currentFiscalYearEndDate));
  }
  /*
  Display the following 3 years by default (for new companies) or if the last available statement year is
  far from the currentPeriodDate */
  if (isEmpty(fiscalYearPeriods) || lastAvailableStatementYear < currentPeriodYear) {
    nextPeriods.push(moment(currentFiscalYearEndDate).add(1, 'years'));
    if (!financialStatement.id) {
      nextPeriods.push(moment(currentFiscalYearEndDate).add(2, 'years'));
      nextPeriods.push(moment(currentFiscalYearEndDate).add(3, 'years'));
    }
  } else {
    // Otherwise, create columns for the next years that are in the financial statement
    statementYears.forEach(year => {
      if (year > currentFiscalYearEnd.year()) {
        nextPeriods.push(moment(currentFiscalYearEndDate).add(year - currentFiscalYearEnd.year(), 'years'));
      }
    });
  }
  // Organize the periods
  prevPeriods.sort((a, b) => a.diff(b));
  nextPeriods.sort((a, b) => a.diff(b));

  const finalAutomaticPeriod = nextPeriods[nextPeriods.length - 1];
  fiscalYearPeriods
    .map(p => moment(p.statement_date))
    .filter(p => p.isAfter(finalAutomaticPeriod))
    .sort((a, b) => a.unix() - b.unix())
    .forEach(p => {
      nextPeriods.push(p);
    });

  // years cannot be repeated as they're the data source of the parent columns but
  // in some FYE and MD combinations, nextPeriods may have different day and month but with equal year
  // so we deal with it here
  const uniqueNextPeriods = nextPeriods.reduce(
    (accumulator, momentObj) => {
      const periodYear = momentObj.year();

      if (!accumulator.years.has(periodYear)) {
        accumulator.years.add(periodYear);
        accumulator.result.push(momentObj);
      }

      return accumulator;
    },
    { years: new Set(), result: [] }
  ).result;

  let lastPrevQuarter = null;

  const sameOrBeforeCurrentFiscalYearEnd = columnDate => columnDate.isSameOrBefore(currentFiscalYearEnd, 'day');

  prevPeriods.forEach((date, indexDate) => {
    const year = date.year();
    const isAdditionalHistoricalYear = year <= defaultLimitYear;
    const periodYear = fiscalYearPeriods.find(period => date.isSame(period.statement_date, 'year')) || {};
    const parentId = generateParentId(periodYear);
    const quarters = getYearQuarters(date, fiscalYearData);

    if (isAdditionalHistoricalYear) {
      tmpAdditionalHistoricalYearsColumns.forEach(column => {
        if (column.year === year) {
          column.id = parentId;
        }
        return column;
      });
    }
    quarters.forEach((quarter, index) => {
      const periodQuarter = auxPeriods.find(p => isFiscalQuarter(p, quarter)) || {};

      generatedPeriods.push({
        statement_date: moment(quarter.date, DEFAULT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT),
        period_type: FISCAL_QUARTER,
        parentColumn: parentId,
        title: quarter.name,
        subtitle: quarter.date,
        ...periodQuarter,
        name: quarter.name,
        date: quarter.date,
        isPrevQuarter: true,
        is_projection: false,
        quarter: index + 1,
      });
    });

    const q4 = moment(quarters[quarters.length - 1].date, MOMENT_DEFAULT_DATE_FORMAT, false);

    // name and date should refer to last quarter of q4
    const isVisibleColumn = isAdditionalHistoricalYear
      ? isAdditionalHistoricalYear && showAdditionalHistoricalYears
      : null;
    generateParentColumns({
      id: parentId,
      statement_date: q4.format(DISPLAY_DATE_FORMAT),
      title: FORMAT_YEAR_TITLE(q4.format('YYYY')),
      period_type: FISCAL_YEAR,
      name: q4.format('YYYY'),
      isPrevYear: true,
      period: periodYear,
      mdMatchesFiscalYearEnd: monthDayFY === monthDayMD,
      isParentWithDivider: sameOrBeforeCurrentFiscalYearEnd(q4) && indexDate === prevPeriods.length - 1,
      year: q4.format('YYYY'),
      isAdditionalHistoricalYear,
      showAdditionalHistoricalYear: showAdditionalHistoricalYears,
      isVisibleColumn,
    });
  });

  lastPrevQuarter = generatedPeriods[generatedPeriods.length - 1];

  uniqueNextPeriods.forEach((date, indexDate) => {
    const periodYear = fiscalYearPeriods.find(p => date.isSame(p.statement_date, 'year'));
    const parentId = generateParentId(periodYear);
    const quarters = getYearQuarters(date, fiscalYearData);

    const nearQuarter = last(
      quarters.filter(quarter =>
        moment(moment(quarter.date, 'MM/DD/YYYY').format('YYYY-MM-DD')).isSameOrBefore(currentPeriod, 'day')
      )
    );

    quarters.forEach((quarter, index) => {
      const periodQuarter = auxPeriods.find(p => isFiscalQuarter(p, quarter)) || {};
      const is_projection = moment(quarter.date, DEFAULT_DATE_FORMAT).isAfter(currentPeriod);
      if (lastPrevQuarter && !is_projection) {
        lastPrevQuarter = quarter;
      }

      const momentDateDefaultFormat = moment(quarter.date, DEFAULT_DATE_FORMAT);

      generatedPeriods.push({
        statement_date: momentDateDefaultFormat.format(DISPLAY_DATE_FORMAT),
        period_type: FISCAL_QUARTER,
        is_projection,
        date: quarter.date,
        parentColumn: parentId,
        title: is_projection ? FIRST_ROW_TITLE : '',
        subtitle: quarter.name,
        ...periodQuarter,
        name: quarter.name,
        isProjectedQuarter: is_projection,
        isChildWithDivider: nearQuarter?.date === quarter.date,
        quarter: index + 1,
      });
    });

    const q4 = moment(quarters[quarters.length - 1].date, MOMENT_DEFAULT_DATE_FORMAT, false);

    generateParentColumns({
      id: parentId,
      statement_date: q4.format(DISPLAY_DATE_FORMAT),
      title: FIRST_ROW_TITLE,
      subtitle: FORMAT_YEAR_TITLE(q4.format('YYYY')),
      period_type: FISCAL_YEAR,
      is_projection: true,
      name: q4.format('YYYY'),
      isProjectedYear: true,
      mdMatchesFiscalYearEnd: monthDayFY === monthDayMD,
      isFirstProjectedYear: indexDate === 0,
      period: periodYear,
      year: q4.format('YYYY'),
    });
  });

  // LTM & NTM
  const firstQuarterDate = moment(measurementDate).subtract(1, 'years');
  const lastQuarterDate = moment(measurementDate).add(1, 'years');
  const proximalQuarters = getRecentQuarters(fiscalYearData, currentFiscalYearEnd);

  // LTM
  const ltmQuarters = getLtmQuarters(proximalQuarters, currentPeriod, firstQuarterDate);
  const periodLTM = auxPeriods.find(period => period.period_type === LTM);
  const ltmParentId = generateParentId(periodLTM);

  ltmQuarters.forEach((quarter, index) => {
    const periodQuarter
      = auxPeriods.find(
        period => period.period_type === LTM_QUARTER && moment(period.statement_date).isSame(quarter.date)
      ) || {};

    generatedPeriods.push({
      statement_date: quarter.date.format(DISPLAY_DATE_FORMAT),
      period_type: LTM_QUARTER,
      parentColumn: ltmParentId,
      title: 'LTM',
      date: quarter.date.format(DEFAULT_DATE_FORMAT),
      subtitle: quarter.name,
      ...periodQuarter,
      name: quarter.name,
      isLTM: true,
      isLTMQuarter: true,
      quarter: index + 1,
    });
  });

  const getReportedStatementDate = period => {
    const date = period.income_statement.reported_statement_date ?? period.statement_date;
    return moment(date).format(END_OF_YEAR_FORMAT);
  };
  const getBalanceSheetReportedStatementDate = period => {
    const date = period.balance_sheet.reported_statement_date ?? period.statement_date;
    return moment(date).format(END_OF_YEAR_FORMAT);
  };

  const ltmStatementMonthDay = getReportedStatementDate(periodLTM);
  const balanceSheetLtmStatementMonthDay = getBalanceSheetReportedStatementDate(periodLTM);
  generateParentColumns({
    id: ltmParentId,
    statement_date: measurementDate,
    title: 'LTM',
    period_type: LTM,
    name: 'LTM',
    subtitle: measurementDate,
    balanceSheetMatchesFiscalYearEnd: monthDayFY === balanceSheetLtmStatementMonthDay,
    mdMatchesFiscalYearEnd: monthDayFY === ltmStatementMonthDay,
    period: periodLTM,
    isLTM: true,
    isLTMYear: true,
    year: String(currentFiscalYearEnd.year()),
  });

  const periodNTM = auxPeriods.find(period => period.period_type === NTM) || {};
  const ntmParentId = generateParentId(periodNTM);

  const ntmQuarters = getNtmQuarters(proximalQuarters, currentPeriod, lastQuarterDate);
  const yearAfterMeasurementDate = moment(measurementDate).add(1, 'years');

  ntmQuarters.forEach((quarter, index) => {
    const periodQuarter
      = auxPeriods.find(
        period => period.period_type === NTM_QUARTER && moment(period.statement_date).isSame(quarter.date)
      ) || {};

    generatedPeriods.push({
      statement_date: quarter.date.format(DISPLAY_DATE_FORMAT),
      period_type: NTM_QUARTER,
      parentColumn: ntmParentId,
      title: 'NTM',
      date: quarter.date.format(DEFAULT_DATE_FORMAT),
      subtitle: quarter.name,
      ...periodQuarter,
      name: quarter.name,
      isNTM: true,
      isNTMQuarter: true,
      quarter: index + 1,
    });
  });

  const ntmStatementMonthDay = getReportedStatementDate(periodNTM);
  generateParentColumns({
    id: ntmParentId,
    statement_date: yearAfterMeasurementDate.format(DISPLAY_DATE_FORMAT),
    title: 'NTM',
    period_type: NTM,
    name: 'NTM',
    subtitle: yearAfterMeasurementDate.format(DISPLAY_DATE_FORMAT),
    period: periodNTM,
    mdMatchesFiscalYearEnd: monthDayFY === ntmStatementMonthDay,
    isNTM: true,
    isNTMYear: true,
    year: String(currentFiscalYearEnd.year() + 1),
  });

  setPeriods(generatedPeriods);
  setCollapsibleColumns(tmpCollapsibleColumns);
  setAdditionalHistoricalYears(tmpAdditionalHistoricalYearsColumns.reverse());
};

export default loadFinancialStatementData;
