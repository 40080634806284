import { find, isEmpty, isNil, sortBy, uniqBy } from 'lodash';
import { AllocationScenarioDetailSecurityModel } from 'api';
import { Fund } from 'common/types/fund';
import { ConfigurationSpreadsheets } from 'common/types/scalarSpreadsheet';
import { AllocationWeightedShareValue, ShareValueSecurity } from 'common/types/valuation';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import {
  ALLOCATION_WEIGHTED_SHARE_VALUES_KEY,
  SHEET_CONFIG_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { Configuration } from 'pages/ValuationsAllocation/hooks';
import { getArrayValue, getNumberOrNAValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { createColumns } from './createColumns';
import { customParser, WeightedShareValuesParserParams } from './customParser';
import { customReverseParser, ReverseParserParams } from './customReverseParser';
import { customRowConfig } from './customRowConfig';
import { CreateWeightedShareValuesConfigurationParams, FundWeightedShareValues, SecuritiesByFund } from './types';

const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;
const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SHARES_TITLE } = SHEET_TITLES_CONSTANTS;

const createWeightedShareValuesConfiguration = (params: CreateWeightedShareValuesConfigurationParams) => {
  const { capTable, fieldAttributes, funds, isUniformCurrency, securities, valuation } = params;

  const { allocation } = getObjectValue(valuation);
  if (!allocation) {
    throw new Error('Allocation is required to create Weighted Share Values Configuration');
  }
  const { allocation_scenarios: allocationScenarios = [], weighted_share_values: weightedShareValues = [] }
    = getObjectValue(allocation);

  const sortedAllocationScenarios = sortBy(allocationScenarios, 'order');

  const { fund_ownership: capTableFundOwnership } = getObjectValue(capTable);
  const { fund_ownership_detail: capTableFundOwnershipDetail = [] } = getObjectValue(capTableFundOwnership);

  const capTableFundOwnershipFunds = uniqBy(capTableFundOwnershipDetail, 'fund');
  const fundOwnershipFunds = getArrayValue(
    capTableFundOwnershipFunds?.reduce((accumulator, current) => {
      const { fund: fundId = 0 } = current;

      const fundInformation = funds?.find(fund => fund?.id === fundId);

      if (!isEmpty(fundInformation)) {
        return [...accumulator, fundInformation];
      }

      return accumulator;
    }, [] as Fund[])
  );

  // If WSV haven't been created, calculate them
  const calculatedWeightedShareValues: AllocationWeightedShareValue[]
    = funds
      ?.map(fund => {
        const fundId = fund.id;
        const wsv = weightedShareValues?.find(wsv => wsv.fund?.id === fundId);
        if (wsv) {
          return wsv;
        }
        // find all the fund ownership details for this fund
        const wsvs
          = capTableFundOwnershipDetail
            .filter(fundOwnership => fundOwnership.fund === fundId)
            .map(fundOwnership => {
              const { security: securityId, security_name: securityName, shares } = fundOwnership;
              return {
                securityId: securityId ?? undefined,
                security: securityName,
                shares: Number(shares ?? 0),
                price: 0,
                unrealized_value: 0,
                xfactor: 0,
                is_custom_security: false,
                invested_capital: 0,
              };
            }) || [];

        return {
          fund: { id: fundId, name: fund?.name },
          name: `${fund?.name} ${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SHARES_TITLE}`,
          share_values: wsvs,
        };
      })
      .filter(wsv => wsv.share_values?.length) ?? [];

  const allocationWeightedShareValuesByFund = getObjectValue(
    calculatedWeightedShareValues?.reduce((accumulator, current) => {
      const { fund, share_values: shareValues = [] } = getObjectValue(current);
      const { id: fundId = 0 } = getObjectValue(fund);

      return {
        ...accumulator,
        [fundId]: shareValues,
      };
    }, {} as FundWeightedShareValues)
  );

  const securitiesByFund = getObjectValue(
    capTableFundOwnershipDetail?.reduce((accumulator, current) => {
      const {
        custom_security_name: customSecurityName,
        fund: fundId = 0,
        invested_capital: investedCapital,
        security_name: securityName,
        security: securityId,
        shares,
      } = getObjectValue(current);

      const fundWeightedShareValues = getObjectValue(allocationWeightedShareValuesByFund?.[fundId]);
      const fundWeightedShareValuesSecurity = find(fundWeightedShareValues, {
        // customSecurityName could be an empty string so we can't use the ?? operator:
        security: customSecurityName || securityName,
      });

      const customSecurity = {
        invested_capital: getNumberValue(investedCapital),
        is_custom_security: isNil(securityId),
        name: getStringValue(isNil(securityId) ? customSecurityName : securityName),
        price: getNumberValue(fundWeightedShareValuesSecurity?.price), // Custom Securities do not have a Price
        security: getStringValue(isNil(securityId) ? customSecurityName : securityName),
        securityId: isNil(securityId) ? undefined : securityId, // Custom Securities do not have a SecurityId
        shares: getNumberValue(shares ?? fundWeightedShareValuesSecurity?.shares),
        unrealized_value: getNumberValue(fundWeightedShareValuesSecurity?.unrealized_value),
        xfactor: getNumberOrNAValue(fundWeightedShareValuesSecurity?.xfactor),
      } as ShareValueSecurity;

      return {
        ...accumulator,
        [fundId]: [...getArrayValue(accumulator?.[fundId]), customSecurity],
      };
    }, {} as SecuritiesByFund)
  );

  const weightedShareValuesByFund = getArrayValue(
    fundOwnershipFunds?.map(fund => {
      const { id: fundId = 0, name: fundName } = getObjectValue(fund);

      const fundShareValues = getArrayValue(securitiesByFund?.[fundId]);

      const fundSecurities = getArrayValue(
        allocationWeightedShareValuesByFund?.[fundId]?.reduce((accumulator, current) => {
          const { security: securityName } = getObjectValue(current);

          // Excluded Securities
          const filteredSecurity = find(securities, { name: securityName });

          if (isNil(filteredSecurity)) {
            return accumulator;
          }

          const fundSecurity = find(fundShareValues, { security: securityName, is_custom_security: false });

          const fundSecurities = fundShareValues?.filter(security => security?.security === securityName);

          const total_shares = fundSecurities?.reduce(
            (accumulator, current) => accumulator + getNumberValue(current?.shares),
            0
          );

          const updatedSecurity = {
            ...current, // Allocation Security
            ...fundSecurity,
            shares: total_shares,
            name: getStringValue(securityName),
          } as ShareValueSecurity;

          return [...accumulator, updatedSecurity];
        }, [] as ShareValueSecurity[])
      );

      const fundCustomSecurities = getArrayValue(fundShareValues?.filter(shareValue => shareValue?.is_custom_security));

      return {
        fund: {
          id: fundId,
          name: getStringValue(fundName),
        },
        name: `${fundName} ${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SHARES_TITLE}`,
        share_values: [...fundSecurities, ...fundCustomSecurities],
      } as AllocationWeightedShareValue;
    })
  );

  const filteredWeightedShareValuesByFund = sortBy(
    weightedShareValuesByFund.filter(fundWeightedShareValues => !isEmpty(fundWeightedShareValues?.share_values)),
    'name'
  );

  const weightedShareValuesSpreadsheets = getArrayValue(
    filteredWeightedShareValuesByFund?.reduce((accumulator, current) => {
      const { fund, name, share_values: shareValues } = getObjectValue(current);
      const { id: fundId = 0 } = getObjectValue(fund);

      const spreadsheetTableTerms = ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE_TERMS(
        getStringValue(fundId?.toString())
      );

      const columns = createColumns({ fund, shareValues });

      const currentSecurities = getArrayValue(
        uniqBy([...getArrayValue(securities), ...getArrayValue(shareValues)], 'name')
      ) as AllocationScenarioDetailSecurityModel[];

      const rowConfig = customRowConfig({
        fund,
        name,
        securities: currentSecurities,
      });

      const parser = (parserParams: WeightedShareValuesParserParams) =>
        customParser({
          ...parserParams,
          allocationScenarios: sortedAllocationScenarios,
          isUniformCurrency,
        });

      const reverseParser = (reverseParserParams: ReverseParserParams) =>
        customReverseParser({
          ...reverseParserParams,
          fund,
          securities: currentSecurities,
          weightedShareValues: calculatedWeightedShareValues,
          allocation,
        });

      const sheet = new SpreadsheetConfig({
        allowConfirmAndDeleteColumn: false,
        allowCopyColumn: false,
        allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
        alwaysDisplayLegend: false,
        columns,
        currencyFormatter: true,
        fieldAttributes,
        format: undefined,
        name: spreadsheetTableTerms.tableName,
        page: ALLOCATION_WEIGHTED_SHARE_VALUES_KEY as unknown as SpreadsheetConfig['page'],
        parser,
        reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
        rowConfig,
        showPreviousColsDivider: false,
        showTitlesColumn: true,
        showToolbar: true,
        showTotalColumn: false,
        tableData: {},
        tableTerms: spreadsheetTableTerms as unknown as SpreadsheetConfig['tableTerms'],
        totalParser: undefined,
        unitsFormatter: true,
      }) as unknown as ConfigurationSpreadsheets;

      return [
        ...accumulator,
        {
          isWeightedShareValues: true,
          name: spreadsheetTableTerms.tableName,
          spreadsheets: {
            [spreadsheetTableTerms.tableName]: sheet,
          },
        } as Configuration,
      ];
    }, [] as Configuration[])
  );

  return weightedShareValuesSpreadsheets;
};

export default createWeightedShareValuesConfiguration;
