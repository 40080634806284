import { isNil } from 'lodash';
import { BLANK_VALUE } from 'common/constants/general';
import { COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX } from 'pages/Portfolio/common/constants/companySummary';
import { CompanyFundInvestment } from 'pages/Portfolio/hooks';
import { generateColumnKey, getNumberValue, getSlugValue, getStringValue, shortDate } from 'utillities';
import { CompanySummaryColumn, CreateColumnKeyNameParams, CreateColumns } from './types';

export const createColumnKeyName = (params: CreateColumnKeyNameParams) => {
  const { name, slug } = params;

  return `${getStringValue(slug)}-${getSlugValue(name)}`;
};

const createColumns: CreateColumns = params => {
  const { fundsInvestments } = params;

  const getSumKey = (current: CompanyFundInvestment) =>
    `${COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX}_${current?.fundSlug}-${current?.security_name}`;

  // Investment Date
  const securityInvestmentDate = fundsInvestments.reduce((accumulator, current) => {
    const sumKey = getSumKey(current);

    return {
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getStringValue(shortDate(current?.investment_date)),
      },
      [sumKey]: {
        value: getStringValue(
          shortDate(
            fundsInvestments.filter(
              fi => fi.fundSlug === current.fundSlug && fi.security_name === current.security_name
            )[0].investment_date
          )
        ),
      },
    };
  }, {}) as CompanySummaryColumn;

  // Invested Capital
  const securityInvestedCapital = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all values
    const sumKey = getSumKey(current);
    const currentInvestmentValue = getNumberValue(current?.investment);
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentInvestmentValue,
      },
      [sumKey]: {
        value: accumulatedSum + currentInvestmentValue,
      },
    };
  }, {});

  // Shares
  const securityShares = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all shares
    const sumKey = getSumKey(current);
    const currentSharesValue = getNumberValue(current?.shares);
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentSharesValue,
      },
      [sumKey]: {
        value: accumulatedSum + currentSharesValue,
      },
    };
  }, {});

  // CSE Shares
  const securityCSEShares = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all cse_shares
    const sumKey = getSumKey(current);
    const currentCSESharesValue = getNumberValue(current?.cse_shares);
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentCSESharesValue,
      },
      [sumKey]: {
        value: accumulatedSum + currentCSESharesValue,
      },
    };
  }, {});

  // Concluded Share Value
  const securityConcludedShareValue = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all weighted share prices (only summing valid numbers)
    const sumKey = getSumKey(current);
    const currentSharePrice = !isNil(current?.weighted_share_price) ? getNumberValue(current.weighted_share_price) : 0;

    return {
      ...accumulator,
      [columnKey]: {
        value: currentSharePrice !== 0 ? currentSharePrice : BLANK_VALUE,
      },
      [sumKey]: {
        value: currentSharePrice !== 0 ? currentSharePrice : BLANK_VALUE,
      },
    };
  }, {});

  // Fully Diluted Ownership %
  const securityFullyDilutedOwnership = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all diluted ownership percentages
    const sumKey = getSumKey(current);
    const currentOwnershipValue = getNumberValue(current?.diluted_ownership);
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentOwnershipValue,
      },
      [sumKey]: {
        value: accumulatedSum + currentOwnershipValue,
      },
    };
  }, {});

  // Realized Value
  const securityRealizedValue = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all realized values (only summing valid numbers)
    const sumKey = getSumKey(current);
    const currentRealizedValue = !isNil(current?.realized_value) ? getNumberValue(current.realized_value) : 0;
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentRealizedValue !== 0 ? currentRealizedValue : BLANK_VALUE,
      },
      [sumKey]: {
        value: accumulatedSum + currentRealizedValue,
      },
    };
  }, {});

  // Unrealized Equity
  const securityUnrealizedEquity = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all unrealized values (only summing valid numbers)
    const sumKey = getSumKey(current);
    const currentUnrealizedValue = !isNil(current?.unrealized_value) ? getNumberValue(current.unrealized_value) : 0;
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentUnrealizedValue !== 0 ? currentUnrealizedValue : BLANK_VALUE,
      },
      [sumKey]: {
        value: accumulatedSum + currentUnrealizedValue,
      },
    };
  }, {});

  // Total Value
  const securityTotalValue = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all total values (only summing valid numbers)
    const sumKey = getSumKey(current);
    const currentTotalValue = !isNil(current?.total_value) ? getNumberValue(current.total_value) : 0;
    const accumulatedSum = Number(accumulator[sumKey]?.value ?? 0);

    return {
      ...accumulator,
      [columnKey]: {
        value: currentTotalValue !== 0 ? currentTotalValue : BLANK_VALUE,
      },
      [sumKey]: {
        value: accumulatedSum + currentTotalValue,
      },
    };
  }, {});

  // MOIC
  const securityMOIC = fundsInvestments.reduce<CompanySummaryColumn>((accumulator, current) => {
    const columnKey = generateColumnKey({
      id: current.id,
      name: createColumnKeyName({
        name: current?.security_name,
        slug: current?.fundSlug,
      }),
      prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    // Calculate the sum of all x_factors (only summing valid numbers)
    const sumKey = getSumKey(current);
    const currentMOICValue = !isNil(current?.x_factor) ? getNumberValue(current.x_factor) : 0; // Use 0 if it's `nil` for summing

    return {
      ...accumulator,
      [columnKey]: {
        value: currentMOICValue !== 0 ? currentMOICValue : BLANK_VALUE,
      },
      [sumKey]: {
        value:
          Number(securityTotalValue[sumKey]?.value) / Number(securityInvestedCapital[sumKey]?.value) || BLANK_VALUE,
      },
    };
  }, {});

  return [
    securityInvestmentDate,
    securityInvestedCapital,
    securityShares,
    securityCSEShares,
    securityConcludedShareValue,
    securityFullyDilutedOwnership,
    securityRealizedValue,
    securityUnrealizedEquity,
    securityTotalValue,
    securityMOIC,
  ];
};

export default createColumns;
